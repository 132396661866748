/*Project custom css*/

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.75rem + 20px);
  line-height: 1.25;
}

.react-date-picker__wrapper {
  border: none !important;
}


.form-check-label {
  color: #ffffff !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-time-picker__clock {
  display: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
}
/* .btn-group {
  display: 'inline' !important;
} */

.InputElement {
  color: #ffffff !important;
}

[data-bs-theme='dark'] {
  --bs-primary: #000000;
  --bs-primary-bg-subtle: #1d1c1c;
  --bs-primary-bg-subtle-dark: #0e0d0d;

  .btn-primary {
    --bs-btn-bg: #000000;
  }
}

.custom-btn {
  display: block;
  width: 200px; /* adjust to your desired width */
  padding: 22px 0!important;
  border-radius: 50px;
  background-color: #333;
  color: #fff;
  text-transform: capitalize!important;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent; /* Start with a transparent border */
  transition: all 0.3s ease;
}
.custom-btn:hover {
  border: 2px solid #323030 !important; /* Change border color on hover */
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); */
}

.custom-btn:disabled {
  background-color: #555!important;
  color: #aaa;
  box-shadow: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.custom-block-btn {
  display: block!important;
  width: 100%; /* adjust to your desired width */
  padding: 22px 0!important;
  border-radius: 50px;
  background-color: #333;
  color: #fff;
  text-transform: capitalize!important;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent; /* Start with a transparent border */
  transition: all 0.3s ease;
}
.custom-block-btn:hover {
  border: 2px solid #323030 !important; /* Change border color on hover */
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); */
}

.custom-block-btn:disabled {
  background-color: #555!important;
  color: #aaa;
  box-shadow: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}


.btn-secondary{
  background-color: #202020 !important;
}

.btn-secondary:hover{
  background-color: #707070 !important;
}

.btn-secondary:active{
  background-color: #ffffff !important;
}


.xl-btn {
  display: block;
  width: 200px; /* adjust to your desired width */
  padding: 22px 0!important;
  border-radius: 50px;
  color: #fff;
  text-transform: capitalize!important;
  font-weight: bold;
  text-align: center;
  margin: 20px auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent; /* Start with a transparent border */
  transition: all 0.3s ease;
}
.xl-btn:hover {

}

.xl-btn:disabled {
  color: #aaa;
  box-shadow: none;
  cursor: not-allowed;
}